import axios from 'axios';
import swal from "sweetalert";
import Cookies from 'js-cookie';
import moment from "moment";
axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // console.log('error ==>', error);
    // console.log('error error.response.status ==>', error.response.status);

    if (error && error.response && 409 === error.response.status) {
        if (error.response !== '') return Promise.resolve(error.response);
    }
    // else if (error && error.response && 403 === error.response.status) {
    //   common.showWarningMessage('Please send proper data.');
    //   // localStorage.clear()
    //   // window.href('/')
    //   // window.reload()
    // }
    // else if (error && error.response && 500 === error.response.status) {
    //   common.showErrorMessage('Internal server error');
    // }
    // else if (error && error.response && 401 === error.response.status) {
    //   common.showErrorMessage('UnAuthorized.');
    //      localStorage.clear()
    // }
    else {
        return Promise.reject(error);
    }
});
export default {

    // for set an item
    setItem(key, value) {
        Cookies.set(key, value);
    },


    // To get the value of item
    getItem(key) {
        return Cookies.get(key);
    },
    // To remove the item
    removeItem(key) {
        if (typeof key === 'string') Cookies.remove(key);
        else key.map(k => Cookies.remove(k))
    },

    formatDate(date) {
        let newDate = moment(date).format("MMMM YYYY");
        return newDate;
    },

    // for email validate
    validateEmail(email) {
        var pattern = new RegExp(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return pattern.test(email);
    },
    // for password validate
    validatePassword(pass) {
        var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,21}$/);
        return pattern.test(pass);
    },

    /*************************************************************************************
          @PURPOSE    : Warning Message.
          @Parameters :{
                          msg: message
                       }                            
      **************************************************************************************/
    showWarningMessage(msg) {
        swal({
            position: 'center',
            icon: 'warning',
            title: msg,
            buttons: false,
            timer: 2000,
            className: 'custom-toaster'
        });
    },
    /*************************************************************************************
     @PURPOSE    : Success Message.
     @Parameters :{
                     msg: message
                  }
 **************************************************************************************/
    showSuccessMessage(msg) {
        swal({
            position: 'center',
            icon: 'success',
            title: msg,
            buttons: false,
            timer: 2000,
            className: 'custom-toaster'
        });
    },
    /*******************************************************************************************
        @PURPOSE    : Call api.
        @Parameters :{
                        url : <url of api>
                        data : <data object (JSON)>
                        method : String (get, post)
                        isForm (Optional) : Boolean - to call api with form data
                        isPublic (Optional) : Boolean - to call api without auth header
                    }
    /*****************************************************************************************/

    callApi(url, body, method, isForm = false, isAuthorized = false) {
        if (body === '') body = {};
        let headers = { 'Content-Type': 'application/json' };
        if (isAuthorized) headers.Authorization = this.getItem('token');
        if (isForm) headers['Content-Type'] = 'multipart/form-data';
        // Api calling
        return new Promise(async (resolve, reject) => {
            await axios({ method, url, headers, data: body })
                .then(response => {
                    let { data } = response;
                    resolve(response);
                    // console.log('data', data);
                    if (data.status === 0) {
                        if (data.statusCode === 407 || data.message === 'Token expired.' || data.message === "Invalid token") {
                            this.removeItem(["token", "lastname", "firstname", "photo", "email"]);
                            window.location.href = '/';
                        }
                    }
                })
                .catch((error) => {
                    const expectedError = error.response && error.response.status;
                    if (expectedError) {
                        this.showWarningMessage(expectedError)
                        // if (expectedError === 401) {
                        //     this.showInfoMessage('Your session has timed out. Please sign in again');
                        //     this.removeItem(['token', "name", "photo", "email"]);
                        //     this.setItem('sessionOut', 'session');
                        // }
                        // else if (expectedError <= 500) {
                        //     this.setItem('sessionOut', 'session');
                        //     this.removeItem(["token", "name", "photo", "email"]);
                        //     this.showInfoMessage('Something went wrong, Please sign in again');
                        // };
                        // window.location.href = '/';
                    }
                    reject(error);
                });
        })
    }

}
